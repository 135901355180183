
import userModule from "@/store/modules/user";
import appConfigModule from "@/store/plugins/appConfig";
import _ from "lodash";
import { Vue, Component } from "vue-property-decorator";

@Component
export default class MainPage extends Vue {
  config = appConfigModule;
}
